import styled, { keyframes } from 'styled-components';

export const ParentWrapper = styled('div')`
  display: flex;
  justify-content: space-between;
  background: white;

  .sideBarUL li a {
    color: black;
  }

  .sideBarUL .item > a:hover {
    background-color: #5281db;
    color: #fff !important;

    /* background: #F8F8F8 */
  }

  @media only screen and (max-width: 767px) {
    display: block;
  }
`;

export const LeftSideBarWidth = styled('div')`
  width: 359px;
`;

export const Input = styled.input`
  max-width: 100%;
  padding: 11px 13px;
  background: #ffffff;
  color: #000000;
  margin-bottom: 0.9rem;
  border-radius: 2px;
  outline: 0;
  border: 1px solid #cbcbcb;
  font-size: 14px;
  // box-shadow: 0 0 3px rgba(0, 0, 0, 0.1), 0 1px 1px rgba(0, 0, 0, 0.1);
  :focus,
  :hover {
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.15), 0 1px 5px rgba(0, 0, 0, 0.1);
  }
`;

const jump = keyframes`
  from{
    transform: translateY(0)
  }
  to{
    transform: translateY(-3px)
  }
`;

export const Button = styled.button`
  max-width: 100%;
  padding: 11px 13px;
  color: rgb(253, 249, 243);
  font-weight: 600;
  text-transform: uppercase;
  background: rgb(49 101 200);
  border: none;
  border-radius: 3px;
  outline: 0;
  cursor: pointer;
  margin-top: 0.6rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease-out;
  :hover {
    background: rgb(200, 50, 70);
    animation: ${jump} 0.2s ease-out forwards;
  }
`;
