import React, { useEffect, useState } from 'react';
import { Loader } from 'react-feather';
import { FaInfoCircle } from 'react-icons/fa';
import styled from 'styled-components';
import { validateLicenseKey } from '../../api';
import AltLayout from '../../components/altLayout';
import { Input } from '../../components/styles/Parent';
import { LS_KEYS } from '../../config/constants';

// src/custom/styles/styles.js

const Login = (props) => {
  const [isDarkThemeActive, setDarkThemeActive] = useState(false);
  const [inputVal, setInput] = useState('');
  const [error, setError] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const [showMore, setShowMore] = useState(false);

  const toggleActiveTheme = () => {
    setDarkThemeActive((prevState) => ({ isDarkThemeActive: !prevState.isDarkThemeActive }));

    window.localStorage.setItem('isDarkThemeActive', JSON.stringify(!isDarkThemeActive));
  };

  useEffect(() => {
    setTimeout(() => {
      setError(false);
    }, 3000);
  }, [error]);

  //   category	Lead Form Open
  // action	VehicleHome Page
  // label	Offer Form - CTA

  const handleClick = async () => {
    window?.gtag &&
      gtag('event', 'Verify Login CTA Click', {
        event_category: 'Login Page',
        event_label: 'Verify Login CTA Click',
      });

    if (!inputVal) {
      alert('Enter a valid license key');
    }

    setLoading(true);

    let response = await validateLicenseKey(inputVal);
    setLoading(false);
    if (response && response.success === true) {
      setError(false);
      // set localstorage
      if (typeof window !== undefined) {
        localStorage.setItem(LS_KEYS.LICENSE_KEY, inputVal);
        localStorage.setItem(LS_KEYS.USER_EMAIL, response?.purchase?.email);

        // add GA event
        window?.gtag && gtag('event', 'Login Success', {});

        window.location.href = '/';
      }
    }

    if (!response) {
      setError(true);
    }
  };

  let timerInterval;

  return (
    <>
      <AltLayout>
        <Wrapper>
          <Form>
            <Title>Enter your license Key</Title>

            <Input
              type="text"
              name="EMAIL"
              autoFocus={false}
              onChange={(e) => setInput(e.target.value)}
              placeholder="Enter License Key"
            />
            {/* <Input
            type="password"
            name="password"
            onChange={(e) => setInput(e.target.value)}
            /> */}

            <button onClick={handleClick} className="form-control btn btn-primary" type="submit">
              Login
            </button>

            <ToolTipDiv>
              <SubText onClick={() => setShowMore(!showMore)}>
                <FaInfoCircle /> How to find license key ?
              </SubText>

              {showMore && (
                <SubText>
                  {' '}
                  Buy the Product on Gumroad then Enter the License key received on your registered
                  email{' '}
                </SubText>
              )}
            </ToolTipDiv>
          </Form>

          {isLoading && (
            <Loader
              type="Circles"
              color="#00BFFF"
              height={100}
              width={100}
              timeout={3000} //3 secs
            />
          )}

          {error && <Text> Invalid Key, please try again ! </Text>}
        </Wrapper>
      </AltLayout>
      {/* 
      <Global styles={[baseStyles, ...styles]} />

      <Header
        location={props.location}
        isDarkThemeActive={false}
        toggleActiveTheme={toggleActiveTheme}
      />

      <ParentWrapper>
        <LeftSideBarWidth className={'hiddenMobile'}>
          <Sidebar location={props.location} />
        </LeftSideBarWidth>

       
      </ParentWrapper>
      <Footer position="fixed" /> */}
    </>
  );
};

export default Login;

const LeftSideBarWidth = styled('div')`
  width: 372px;
`;

const Wrapper = styled.section`
max-width:1000px;
`;

const Form = styled.div`
  margin: auto;
  width: 100%;
  max-width: 475px;
  padding: 1.3rem;
  display: flex;
  flex-direction: column;
  position: relative;
  border: 1px solid #d0d0d0;
  border-radius: 8px;
  margin-bottom: 30px;
  margin-top:3rem;
  box-shadow: 1px 2px 3px 2px rgb(0 0 0 / 20%);

  @media (max-width: 500px) { {
    margin-top:2rem;
  }

  
`;

const ToolTipDiv = styled.div`
  margin-top: 20px;
  font-size: 12px;
  color: blue;
`;

const Title = styled.h2`
  font-weight: normal;
  color: #2a2a29;
  text-shadow: 0 1px 3px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.1);
  text-align: center;
  margin-bottom: 2rem;
`;

const Text = styled.p`
  font-weight: normal;
  color: #2a2a29;
  text-align: center;
  padding: 1rem;
  margin-bottom: 2rem;
  color: red;
`;

const SubText = styled.p`
  font-weight: normal;
  cursor: pointer;
  color: #a48080;
  font-size: 13px;
  margin-top: 5px;
  user-select: none;
`;
